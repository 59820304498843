#thank_you
{
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: flex;
    justify-content: center;
}

#thank_you h1
{
    font-family: 'rand bold';
    margin-top: .76rem;
    margin-bottom: 2rem;
    text-align:center;
    width: 100%;
}

#thank_you h2
{
    font-family: 'rand bold';
    margin-bottom: 2rem;
    text-align:center;
    width: 100%;
    font-size: 4rem;
    font-weight: 400;
}

#thank_you h3
{
    font-family: 'rand bold';
    margin-bottom: 2rem;
    text-align:center;
    width: 100%;
    font-weight: 400;
}

#thank_you p {
    font-family: 'rand regular';
    text-align: center;
    font-size: 1.5rem;
}

#thank_you img[alt="check"]
{
    max-width: 300px;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}