#contract h1
{
    font-family: 'rand bold', serif;
    margin-top: .75rem;
    margin-bottom: 2rem;
}

#contract iframe
{
    width: 80%;
    min-height: 80vh;
}

#contract .center {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
}

#contract .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}


#contract
{
    padding-top: 3rem;
    padding-bottom: 3rem;
    font-family: sofia-pro, sans-serif;
}

#contract label
{
    font-weight: 600;
    font-size: 1rem;
}

#contract input
{
    font-size: 1rem;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    padding: 0.5rem 1rem;
}

.form-control {
    height: auto;
}


#contract .form-check
{
    height: 3rem;
    display: flex;
    align-items: center;
}

#contract .form-check-input
{
    margin-top: 0;
    width: 1rem;
    height: 1rem;
}

#contract .form-group.checked {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.react-datepicker__input-container input[type="text"] {
    width: 100%;
}

.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
    background-color: var(--theme-color-1) !important;
}

#contract .form-group.checked label {
    margin-bottom: 0;
    margin-left: 1rem;
}

#contract .form-check-label
{
    margin-left: 1rem;
}

#contract .btn
{
    padding: 0.5rem;
    font-size: 1.2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 2rem;
}

.submit {
    background-color: var(--theme-color-1);
    border-radius: 7px;
    font-size: 1rem !important;
    padding: 0.7rem 2rem !important;
    border: none;
    color: white;
    transition: all .1s ease-in-out;
}

.submit:hover, .submit:active, .submit:focus {
    border: none;
    background-color: var(--theme-color-2);
}

.submit:disabled {
    background-color: var(--theme-color-3);
    filter: brightness(1.15);
}