body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --theme-color-1: #5714ac;
  --theme-color-2: #4f00c1;
  --theme-color-3: rgb(158, 131, 191);
}

/* import Rand font family */
@font-face {
  font-family: "rand black italic";
  src: url("./fonts/Rand-Black-Italic.otf");
}
@font-face {
  font-family: "rand black";
  src: url("./fonts/Rand-Black.otf");
}
@font-face {
  font-family: "rand bold italic";
  src: url("./fonts/Rand-Bold-Italic.otf");
}
@font-face {
  font-family: "rand bold";
  src: url("./fonts/Rand-Bold.otf");
}
@font-face {
  font-family: "rand heavy italic";
  src: url("./fonts/Rand-Heavy-Italic.otf");
}
@font-face {
  font-family: "rand heavy";
  src: url("./fonts/Rand-Heavy.otf");
}

@font-face {
  font-family: "rand italic";
  src: url("./fonts/Rand-Italic.otf");
}
@font-face {
  font-family: "rand light italic";
  src: url("./fonts/Rand-Light-Italic.otf");
}
@font-face {
  font-family: "rand light";
  src: url("./fonts/Rand-Light.otf");
}
@font-face {
  font-family: "rand medium italic";
  src: url("./fonts/Rand-Medium-Italic.otf");
}
@font-face {
  font-family: "rand medium";
  src: url("./fonts/Rand-Medium.otf");
}
@font-face {
  font-family: "rand regular";
  src: url("./fonts/Rand-Regular.otf");
}
@font-face {
  font-family: "rand thin italic";
  src: url("./fonts/Rand-Thin-Italic.otf");
}
@font-face {
  font-family: "rand thin";
  src: url("./fonts/Rand-Thin.otf");
}

/* Default Font Family */
p,
a,
span,
div {
  font-family: "rand regular";
}

h1,
h2,
h3 {
  font-family: "rand heavy";
}

h4,
h5,
h6 {
  font-family: "rand bold";
}